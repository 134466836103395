<template>
  <b-container class="px-0 pb-0 mb-0" fluid>
    <b-card>
      <b-row>
        <b-col cols="6">
          <span>{{ lang("t_queues") }}</span>
          <v-select
            class="mt-50"
            v-model="selected_queues"
            :reduce="(option) => option.internal_name"
            label="display_name"
            value="internal_name"
            :options="queues"
            :placeholder="lang('t_queue')"
            multiple
            :closeOnSelect="false"
            @input="
              () => {
                get_live_queue();
                get_live_agent();
              }
            "
          >
            <template #list-header>
              <div class="w-100 mb-50">
                <b-button
                  class="w-50"
                  size="sm"
                  variant="outline-primary"
                  @click="
                    () => {
                      selected_queues = queues.map((e) => e.internal_name);
                      get_live_queue();
                      get_live_agent();
                    }
                  "
                >
                  <feather-icon size="14" icon="CheckCircleIcon" />
                  {{ lang("t_selectAll") }}
                </b-button>
                <b-button
                  class="w-50"
                  size="sm"
                  variant="outline-danger"
                  @click="
                    () => {
                      selected_queues = [];
                      get_live_queue();
                      get_live_agent();
                    }
                  "
                >
                  <feather-icon size="14" icon="XOctagonIcon" />
                  {{ lang("t_cleanSelections") }}
                </b-button>
              </div>
            </template>
          </v-select>
        </b-col>
        <b-col cols="6">
          <span>{{ lang("t_queueGroup") }}</span>
          <v-select
            class="mt-50"
            v-model="selected_queue_group"
            :reduce="(option) => option"
            label="display_name"
            :options="queue_groups"
            :placeholder="lang('t_queueGroup')"
            multiple
            :closeOnSelect="false"
            @input="queue_group_changed"
          >
          </v-select>
        </b-col>
      </b-row>
      <b-row class="mx-0 mt-1">
        <b-col
          class="px-25"
          lg="3"
          md="4"
          sm="6"
          v-for="(item, i) in Object.values(queue_list)"
          :key="i"
        >
          <b-card
            no-body
            :border-variant="
              item.AwaitingInteractionsCount > 0 ? 'danger' : 'secondary'
            "
          >
            <template #header>
              <h3
                style="
                  display: inline-block;
                  width: 16vw;
                  white-space: nowrap;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                "
                class="mb-0"
              >
                {{
                  queues.find((e) => e.internal_name == item.Queue)
                    ? queues.find((e) => e.internal_name == item.Queue)
                        .display_name
                    : item.Queue
                }}
              </h3>
              <feather-icon
                v-if="item.AwaitingInteractionsCount > 0"
                icon="BellIcon"
                size="32"
                class="text-danger"
                :badge="item.AwaitingInteractionsCount"
                badge-classes="badge-danger badge-glow"
              />
            </template>
            <b-row class="text-center mx-0 pb-50">
              <b-col cols="6" class="flex-column py-1">
                <!-- <b-card-text class="text-muted mb-0"> Cevaplanan </b-card-text>
                <h3 class="font-weight-bolder mb-50">
                  {{
                    `${item.AnsweredCount} (%${
                      Math.round(
                        (item.AnsweredCount / item.TotalInteractionsCount) * 100
                      ) || 0
                    })`
                  }}
                </h3> -->
                <b-media no-body class="my-2">
                  <b-media-aside class="mr-1">
                    <b-avatar
                      style="width: 5em; height: 5em"
                      :variant="'light-success'"
                    >
                      <feather-icon size="24" :icon="'ThumbsUpIcon'" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h2 style="display: inline" class="font-weight-bolder mb-0">
                      {{ item.AnsweredCount }}

                      <h5
                        :style="{
                          marginLeft: '2px',
                          display: 'inline',
                          opacity: 0.5,
                        }"
                      >
                        ({{
                          `%${
                            Math.round(
                              (item.AnsweredCount /
                                item.TotalInteractionsCount) *
                                100
                            ) || 0
                          }`
                        }})
                      </h5>
                    </h2>
                    <h4 class="font-weight-bolder mb-0"></h4>
                    <b-card-text class="font-small-4 mb-0">
                      {{ lang("t_answered") }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>

              <b-col cols="6" class="flex-column py-1">
                <!-- <b-card-text class="text-muted mb-0"> Kaçan </b-card-text>
                <h3 class="font-weight-bolder mb-0">
                  {{
                    `${item.AbandonedCount} (%${
                      Math.round(
                        (item.AbandonedCount / item.TotalInteractionsCount) *
                          100
                      ) || 0
                    })`
                  }}
                </h3> -->
                <b-media no-body class="my-2">
                  <b-media-aside class="mr-1">
                    <b-avatar
                      style="width: 5em; height: 5em"
                      :variant="'light-danger'"
                    >
                      <feather-icon size="24" :icon="'ThumbsDownIcon'" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h2 style="display: inline" class="font-weight-bolder mb-0">
                      {{ item.AbandonedCount }}

                      <h5
                        :style="{
                          marginLeft: '2px',
                          display: 'inline',
                          opacity: 0.5,
                        }"
                      >
                        ({{
                          `%${
                            Math.round(
                              (item.AbandonedCount /
                                item.TotalInteractionsCount) *
                                100
                            ) || 0
                          }`
                        }})
                      </h5>
                    </h2>
                    <h4 class="font-weight-bolder mb-0"></h4>
                    <b-card-text class="font-small-4 mb-0">
                      {{ lang("t_abandoned") }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
            <b-row class="text-center mx-0 px-1 mb-1">
              <b-col cols="3" class="border-right flex-column">
                <b-card-text class="text-info mb-0">
                  {{ lang("t_total") }}
                </b-card-text>
                <h3 class="font-weight-bolder mb-50">
                  {{ item.Agent.Total }}
                </h3>
              </b-col>

              <b-col
                cols="3"
                class="px-0 border-right d-flex align-items-between flex-column"
              >
                <b-card-text class="text-success mb-0">
                  {{ lang("t_available") }}
                </b-card-text>
                <h3 class="font-weight-bolder mb-0">
                  {{ item.Agent.Available }}
                </h3>
              </b-col>
              <b-col
                cols="3"
                class="px-0 border-right d-flex align-items-between flex-column"
              >
                <b-card-text class="text-warning mb-0">
                  {{ lang("t_inCall") }}
                </b-card-text>
                <h3 class="font-weight-bolder mb-0">
                  {{ item.Agent.InInteraction }}
                </h3>
              </b-col>
              <b-col
                cols="3"
                class="px-0 d-flex align-items-between flex-column"
              >
                <b-card-text class="text-danger mb-0">
                  {{ lang("t_paused") }}
                </b-card-text>

                <h3 class="font-weight-bolder mb-0">
                  {{ item.Agent.Paused }}
                </h3>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import {
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BButton,
  BBadge,
  BContainer,
  BRow,
  BCol,
  BCard,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
var audio = new Audio("sounds/ding.mp3");

export default {
  components: {
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardText,
    BBadge,
    vSelect,
    BButton,
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      queue_list: Object.create(null),
      // {
      //   Queue: 'Akbank İdari',
      //   AwaitingInteractionsCount: 0,
      //   Agent: {
      //     Available: 3,
      //     Paused: 3,
      //     InInteraction: 3,
      //   }
      // },

      all_queues: [],
      queues: [],
      selected_queues: [],
      queue_groups: [],
      selected_queue_group: [],
      interval1: {},
      interval2: {},
      interval3: {},
    };
  },
  methods: {
    GetQueues: async function () {
      var response = (await this.$http_in.get(`spv/v1/Queue`)).data;
      // console.log('queues', response);
      this.queues = [];
      let p_queues = globalThis.permissions["queue"];
      this.all_queues = response;
      for (const item of response) {
        if (p_queues.includes(item.internal_name)) {
          this.queues.push(item);
          // this.selected_queues.push(item.internal_name);
        } else {
          this.queues.filter(e => e.internal_name != item.internal_name)
        }
      }
    },
    GetQueueGroup: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/QueueGroup`)).data;

      this.queue_groups = response;
    },
    queue_group_changed() {
      // console.log("queue_group_changed", this.selected_queue_group)
      let queues = [];
      for (const item of this.selected_queue_group) {
        queues = [...queues, ...item.queues.map((e) => e.internal_name)];
      }
      // console.log("queues", queues)

      this.selected_queues = Array.from(new Set([...this.selected_queues, ...queues]));
      this.get_live_queue();
      this.get_live_agent();
    },

    get_live_queue: async function () {
      var response = (await this.$http_in.get(`qmgr/v1/LiveQueue`)).data;
      this.queue_list = Object.create(null);
      let total_calls = {
        TotalInteractionsCount: 0,
        AnsweredCount: 0,
        AwaitingInteractionsCount: 0,
        AbandonedCount: 0,
      };

      this.queue_list[globalThis._lang("t_total")] = {
        Queue: globalThis._lang("t_total"),
        TotalInteractionsCount: total_calls.TotalInteractionsCount,
        AnsweredCount: total_calls.AnsweredCount,
        AwaitingInteractionsCount: total_calls.AwaitingInteractionsCount,
        AbandonedCount: total_calls.AbandonedCount,
        Agent: {
          Total: 0,
          Available: 0,
          Paused: 0,
          InInteraction: 0,
        },
      };
      for (const item of response) {
        if (this.selected_queues.includes(item.Name)) {
          this.queue_list[item.Name] = {
            Queue: item.Name,
            TotalInteractionsCount: item.Statistics.TotalInteractionsCount,
            AnsweredCount: item.Statistics.AnsweredCount,
            AwaitingInteractionsCount: item.Statistics.AwaitingInteractionsCount,
            AbandonedCount: item.Statistics.AbandonedCount,
            Agent: {
              Total: 0,
              Available: 0,
              Paused: 0,
              InInteraction: 0,
            },
          };

          total_calls.TotalInteractionsCount += item.Statistics.TotalInteractionsCount;
          total_calls.AnsweredCount += item.Statistics.AnsweredCount;
          total_calls.AwaitingInteractionsCount += item.Statistics.AwaitingInteractionsCount;
          total_calls.AbandonedCount += item.Statistics.AbandonedCount;

        }
      }

      this.queue_list[globalThis._lang("t_total")] = {
        Queue: globalThis._lang("t_total"),
        TotalInteractionsCount: total_calls.TotalInteractionsCount,
        AnsweredCount: total_calls.AnsweredCount,
        AwaitingInteractionsCount: total_calls.AwaitingInteractionsCount,
        AbandonedCount: total_calls.AbandonedCount,
        Agent: {
          Total: 0,
          Available: 0,
          Paused: 0,
          InInteraction: 0,
        },
      };



      // console.log("aabbcc", this.queue_list);
      // this.top_bar_info[0].content = this.agents.filter(e => e.visible_in_reports).length;
      // this.top_bar_info[1].content = this.agents.filter(e => e.Status == 'Available' && e.visible_in_reports).length;
      // this.top_bar_info[2].content = this.agents.filter(e => e.Status == 'InInteraction' && e.visible_in_reports).length;
      // let break_count = 0;
      // let paused_agent = this.agents.filter(e => e.Reason != '' && e.Status == 'Paused' && e.visible_in_reports);
      // for (const row of paused_agent) {
      //   if (this.status_categories.find(j => j._id == this.status_type[row.Reason])?.internal_name == 'break') {
      //     break_count++;
      //   }
      // }
      // this.top_bar_info[3].content = break_count;
    },
    get_live_agent: async function () {
      var response = (await this.$http_in.get(`qmgr/v1/LiveAgent`)).data;
      // console.log("get_live_agent", response);
      this.agents = response;

      let total_agents = {
        Total: new Set(),
        Available: new Set(),
        Paused: new Set(),
        InInteraction: new Set(),
      };

      for (const row of response) {
        for (const queue of row.Queues.Value) {
          // console.log(row.Username, row.Status, queue)
          if (this.selected_queues.includes(queue) && this.selected_queues.length > 0) {
            if (row.Status == "Available") {
              this.queue_list[queue].Agent.Available++;
              total_agents.Available.add(row.Username);
            }
            if (row.Status == "Paused") {
              let __statu = globalThis.statuses.find(e => e.display_name == row.Reason);
              if (__statu) {
                let __category = globalThis.status_categories.find(e => e._id == __statu.status_category_id);
                if (__category && ['Mola', 'Break'].includes(__category.display_name)) {
                  this.queue_list[queue].Agent.Paused++;
                  total_agents.Paused.add(row.Username);
                }
              }
            }
            if (row.Status == "InInteraction") {
              this.queue_list[queue].Agent.InInteraction++;
              total_agents.InInteraction.add(row.Username);

            }
            this.queue_list[queue].Agent.Total++;
            total_agents.Total.add(row.Username);
          }
        }
      }
      this.queue_list[globalThis._lang("t_total")].Agent = {
        Total: total_agents.Total.size,
        Available: total_agents.Available.size,
        Paused: total_agents.Paused.size,
        InInteraction: total_agents.InInteraction.size,
      }

      this.queue_list = { ...this.queue_list };
    },
    AgentStatusChange(data) {
      // console.log("AgentStatusChange", data);
      let _user = this.agents.find((e) => e.Username == data.Username);

      if (_user != undefined) {
        _user.Reason = data.Status == data.Reason;
        _user.Status = data.Status;
      }
    },
    AgentJoinedQueue(data) {
      if (this.agents.find((e) => e.Username == data.Username) == undefined) {
        this.agents.push({
          ...data,
          Reason: "Offline",
          visible_in_reports: this.visible_users[data.Username + "@" + data.Domain],
        });
      }
    },
    AgentLeftQueue(data) {
      this.agents = this.agents.filter((e) => e.Username != data.Username);
    },
    InteractionJoinedQueue(data) {
      if (this.selected_queues.includes(data.Queue)) {
        this.queue_list[data.Queue].TotalInteractionsCount++;
        this.queue_list[data.Queue].AwaitingInteractionsCount++;

      }
    },
    InteractionPulledBack(data) {
      if (this.selected_queues.includes(data.Queue)) {
        // this.queue_list[data.Queue].AwaitingInteractionsCount++;

      }
    },
    InteractionSentToAgent(data) {
      // if (this.selected_queues.includes(data.Queue)) {
      //   this.queue_list[data.Queue].AwaitingInteractionsCount--;
      // }
    },
    AgentAcceptedInteraction(data) {
      if (this.selected_queues.includes(data.Queue)) {
        this.queue_list[data.Queue].AnsweredCount++;
        this.queue_list[data.Queue].AwaitingInteractionsCount--;
      }
    },
    InteractionAbandoned(data) {
      if (this.selected_queues.includes(data.Queue)) {
        this.queue_list[data.Queue].AbandonedCount++;
        this.queue_list[data.Queue].AwaitingInteractionsCount--;
      }
    },
    InteractionRemovedFromQueue(data) {
      if (this.selected_queues.includes(data.Queue)) {
        this.queue_list[data.Queue].AwaitingInteractionsCount--;
      }
    },
  },
  mounted: async function () {
    const this_data = JSON.parse(localStorage.getItem("wallboardData"));
    // console.log("this_data", this_data);
    if (![undefined, null].includes(this_data)) {
      for (let [key, val] of Object.entries(this_data)) {
        this[key] = val;
      }
    }
    await this.GetQueues();
    await this.GetQueueGroup();
    await this.get_live_queue();
    await this.get_live_agent();

    globalThis.wallboard = this;

    // globalThis._voyce.$qm.on('AgentStatusChange', (data) => {

    // });

    // globalThis._voyce.$qm.on('AgentJoinedQueue', (data) => {
    //   console.log("AgentJoinedQueueSPV", data);

    // });

    // globalThis._voyce.$qm.on('AgentLeftQueue', (data) => {
    //   console.log("AgentLeftQueueSPV", data);

    // });

    // globalThis._voyce.$qm.on('InteractionJoinedQueue', (data) => {

    // });
    // globalThis._voyce.$qm.on('InteractionPulledBack', (data) => {

    // });
    // globalThis._voyce.$qm.on('InteractionSentToAgent', (data) => {

    // });
    // globalThis._voyce.$qm.on('AgentAcceptedInteraction', (data) => {

    // });
    // globalThis._voyce.$qm.on('InteractionAbandoned', (data) => {

    // });
    const _self = this;
    _self.interval1 = setInterval(() => {


      for (const item of Object.values(_self.queue_list).filter(e => e.Queue != 'Toplam')) {
        item.Agent = {
          Total: 0,
          Available: 0,
          Paused: 0,
          InInteraction: 0,
        };
      }

      for (const row of _self.agents) {
        for (const queue of row.Queues.Value) {
          if (_self.selected_queues.includes(queue) && _self.selected_queues.length > 0) {
            if (row.Status == "Available") {
              _self.queue_list[queue].Agent.Available++;
            }
            if (row.Status == "Paused") {
              let __statu = globalThis.statuses.find(e => e.display_name == row.Reason);
              if (__statu) {
                let __category = globalThis.status_categories.find(e => e._id == __statu.status_category_id);
                if (__category && ['Mola', 'Break'].includes(__category.display_name)) {
                  _self.queue_list[queue].Agent.Paused++;

                }
              }
            }
            if (row.Status == "InInteraction") {
              _self.queue_list[queue].Agent.InInteraction++;
            }
            _self.queue_list[queue].Agent.Total++;
          }
        }
      }


      _self.queue_list = { ..._self.queue_list };
    }, 2000);

    _self.interval2 = setInterval(async () => {
      await globalThis.wallboard.get_live_queue();
      await globalThis.wallboard.get_live_agent();
    }, 1000 * 60);

    _self.interval3 = setInterval(async () => {

      for (const key of Object.keys(wallboard.queue_list)) {
        if (_self.queue_list[key].AwaitingInteractionsCount >= 1) {
          audio.play();
        }
      }

    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.interval1);
    clearInterval(this.interval2);
    clearInterval(this.interval3);
    localStorage.setItem("wallboardData", JSON.stringify(this.$data));
  },
};
</script>

<style></style>
